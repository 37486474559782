<template>
  <div class="mx-5">
    <v-breadcrumbs
      :items="[
        { text: 'ΠΔΕ' },
        {
          text: 'ΕΡΓΑ',
          disabled: false,
          link: true,
          exact: true,
          to: '/pde/projects',
        },
        { text: 'ΝΕΟ ΕΡΓΟ' },
      ]"
      divider=">"
    ></v-breadcrumbs>

    <v-card class="pa-4">
      <v-form @submit.prevent="submit">
        <v-row>
          <v-col class="text-caption font-weight-light">
            ( * ) <v-icon small right>mdi-arrow-right</v-icon> Υποχρεωτικό Πεδίο
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pr-6">
            <v-text-field
              label="Τίτλος *"
              v-model="form.title"
              :error-messages="errorMessages.title"
            ></v-text-field>

            <v-autocomplete
              clearable
              label="Περιφερειακή ενότητα *"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.unitId"
              :items="lists.units"
              :loading="lists.units.length == 0"
              :error-messages="errorMessages.unitId"
            ></v-autocomplete>

            <v-autocomplete
              clearable
              label="Απόφαση *"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.pdeDecisionId"
              :items="lists.decisions"
              :loading="lists.decisions.length == 0"
              :error-messages="errorMessages.pdeDecisionId"
            ></v-autocomplete>

            <v-autocomplete
              clearable
              label="Είδος έργου *"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.jobTypeId"
              :items="lists.jobTypes"
              :loading="lists.jobTypes.length == 0"
              :error-messages="errorMessages.jobTypeId"
            ></v-autocomplete>

            <v-autocomplete
              clearable
              label="Φάση έργου *"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.jobPhaseId"
              :items="lists.jobPhases"
              :loading="lists.jobPhases.length == 0"
              :error-messages="errorMessages.jobPhaseId"
            ></v-autocomplete>

            <v-autocomplete
              clearable
              label="Υποτομέας *"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.pdeSubsectorId"
              :items="lists.subsectors"
              :loading="lists.subsectors.length == 0"
              :error-messages="errorMessages.pdeSubsectorId"
            ></v-autocomplete>

            <v-text-field
              label="Προϋπολογισμός *"
              v-model="form.budget"
              :error-messages="errorMessages.budget"
            ></v-text-field>
          </v-col>

          <v-col cols="4" class="pr-6">
            <v-autocomplete
              clearable
              label="Δήμος"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.municipalityId"
              :items="lists.municipalities"
              :loading="lists.municipalities.length == 0"
            ></v-autocomplete>

            <v-text-field
              label="Κωδικός περιφερειακής ενότητας/δήμου"
              v-model="form.unitMunicipalityCodeId"
            ></v-text-field>

            <v-text-field
              label="Φορέας υλοποίησης"
              v-model="form.implementingEntity"
            ></v-text-field>

            <v-text-field
              label="Φορέας αρμοδιότητας"
              v-model="form.propertyEntity"
            ></v-text-field>

            <v-text-field
              label="Προϊσταμένη αρχή"
              v-model="form.supervisionAuthority"
            ></v-text-field>

            <v-text-field
              label="Διευθύνουσα υπηρεσία"
              v-model="form.managementService"
            ></v-text-field>

            <v-text-field
              label="Υπεύθυνος επικοινωνίας"
              v-model="form.contactDetails"
            ></v-text-field>

            <v-autocomplete
              clearable
              label="Φάση έργου αρχή έτους"
              item-text="name"
              item-value="id"
              no-data-text="Δεν βρέθηκαν αποτελέσματα"
              v-model="form.newYearJobPhaseId"
              :items="lists.jobPhases"
              :loading="lists.jobPhases.length == 0"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-text-field
              label="Κωδικός Τράπεζας"
              v-model="form.bankCode"
            ></v-text-field>

            <v-text-field label="ΙΒΑΝ" v-model="form.iban"></v-text-field>

            <v-text-field label="ΣΑΕΠ" v-model="form.saepCode"></v-text-field>

            <v-text-field label="ΑΔΑ" v-model="form.ada"></v-text-field>

            <v-text-field label="ΚΑΕ" v-model="form.kae"></v-text-field>

            <v-text-field
              label="Έτος απένταξης"
              v-model="form.disengagementYear"
            ></v-text-field>

            <v-checkbox
              class="mb-2"
              label="Έχει οριστεί υπόλογος;"
              v-model="form.hasDefinedAccountable"
            ></v-checkbox>

            <v-text-field
              label="Υπόλογος"
              v-model="form.accountable"
              :disabled="!form.hasDefinedAccountable"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider class="my-8"></v-divider>

        <v-row>
          <v-col cols="5" class="pl-12 mr-6">
            <v-text-field
              label="Αριθμός πρωτοκόλλου απόφασης"
              v-model="form.pdeDecisionProtocolNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="5">
            <v-menu
              offset-y
              min-width="auto"
              v-model="decisionProtocolNumberDateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-on="on"
                  readonly
                  persistent-hint
                  hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                  append-icon="mdi-calendar"
                  label="Ημερομηνία πρωτοκόλλου απόφασης"
                  :value="decisionProtocolNumberDateFormatted"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.decisionProtocolNumberDate"
                @input="decisionProtocolNumberDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-file-input
              chips
              multiple
              outlined
              class="no-message"
              label="Συνημμένα αρχεία αριθμού πρωτοκόλλου απόφασης"
              v-model="selectedFiles.decisionProtocolFiles"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" class="pl-12">
            <v-text-field
              label="ΑΔΑΜ πρωτογενούς αιτήματος"
              v-model="form.adamPrimaryRequest"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-file-input
              chips
              multiple
              outlined
              class="no-message"
              label="Συνημμένα αρχεία ΑΔΑΜ πρωτογενούς αιτήματος"
              v-model="selectedFiles.adamPrimaryRequestFiles"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" class="pl-12">
            <v-text-field
              label="ΑΔΑΜ εγκεκριμένου αιτήματος"
              v-model="form.adamConfirmedRequest"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-file-input
              chips
              multiple
              outlined
              class="no-message"
              label="Συνημμένα αρχεία ΑΔΑΜ εγκεκριμένου αιτήματος"
              v-model="selectedFiles.adamConfirmedRequestFiles"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-divider class="my-8"></v-divider>

        <v-row>
          <v-col cols="12">
            <v-file-input
              chips
              multiple
              outlined
              class="no-message"
              label="Συνημμένα αρχεία έργου"
              v-model="selectedFiles.jobAttachmentFiles"
            >
            </v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pl-11">
            <v-textarea
              outlined
              rows="2"
              label="Παρατηρήσεις"
              class="no-message"
              v-model="form.comments"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-divider class="my-8"></v-divider>

        <v-row justify="center" class="pb-4">
          <v-btn rounded text class="mr-4" @click="$router.go(-1)">
            <!-- TODO: @click="cancelConfirmationDialog = true" -->
            ακυρωση
          </v-btn>

          <v-btn
            rounded
            class="px-8"
            color="primary darken-2"
            type="submit"
            :loading="submitLoader"
          >
            Αποθηκευση
          </v-btn>
        </v-row>
      </v-form>
    </v-card>

    <!-- TODO: confirmation message for back and submit -->
    <!-- <v-dialog v-model="cancelConfirmationDialog" persistent max-width="500px">
    </v-dialog> -->

    <v-snackbar
      text
      content-class="font-weight-medium text-body-1"
      v-model="snackbar.open"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template #action>
        <v-btn icon @click="snackbar.open = false">
          <v-icon color="grey darken-3">mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { required, decimal } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      lists: {
        decisions: [],
        subsectors: [],
        units: [],
        municipalities: [],
        jobTypes: [],
        jobPhases: [],
      },
      form: {
        title: null,
        managementService: null,
        unitId: null,
        pdeDecisionId: null,
        bankCode: null,
        pdeDecisionProtocolNumber: null,
        pdeSubsectorId: null,
        jobTypeId: null,
        jobPhaseId: null,
        ada: null,
        hasDefinedAccountable: null,
        accountable: null,
        hasDefinedAccountable: null,
        adamPrimaryRequest: null,
        kae: null,
        implementingEntity: null,
        municipalityId: null,
        supervisionAuthority: null,
        iban: null,
        pdeDecisionProtocolNumberDate: null,
        unitMunicipalityCodeId: null,
        newYearJobPhaseId: null,
        disengagementYear: null,
        adamConfirmedRequest: null,
        saepCode: null,
        propertyEntity: null,
        unitMunicipalityCodeId: null,
        contactDetails: null,
        budget: null,
        comments: null,
        pdeJobAdamConfirmedRequestAttachmentsIds: [],
        pdeJobAdamPrimaryRequestAttachmentsIds: [],
        pdeJobDecisionProtocolAttachmentsIds: [],
        pdeJobAttachmentsIds: [],
      },
      // are not uploaded
      selectedFiles: {
        adamConfirmedRequestFiles: [],
        adamPrimaryRequestFiles: [],
        decisionProtocolFiles: [],
        jobAttachmentFiles: [],
      },
      snackbar: {
        open: false,
        text: null,
        color: null,
      },
      submitLoader: false,
      cancelConfirmationDialog: false,
      decisionProtocolNumberDateMenu: false,
    };
  },
  validations: {
    form: {
      title: { required },
      unitId: { required },
      pdeDecisionId: { required },
      jobTypeId: { required },
      jobPhaseId: { required },
      pdeSubsectorId: { required },
      budget: { required },
    },
  },
  computed: {
    errorMessages() {
      let errors = {
        title: [],
        unitId: [],
        budget: [],
        jobTypeId: [],
        jobPhaseId: [],
        pdeDecisionId: [],
        pdeSubsectorId: [],
      };
      for (let key in errors) {
        if (!this.$v.form[key]?.$dirty) continue;
        if (!this.$v.form[key]?.required) errors[key].push("Υποχρεωτικό πεδίο");
        // if (!this.$v.credits.decimal) errors.push("Πρέπει να είναι αριθμός");
      }
      return errors;
    },
    decisionProtocolNumberDateFormatted() {
      if (!this.form.decisionProtocolNumberDate) return null;

      const [year, month, day] =
        this.form.decisionProtocolNumberDate.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    async submit() {
      this.submitLoader = true;
      try {
        // check validations
        this.$v.$touch();

        // show error message if unsuccessful
        if (this.$v.$invalid) {
          this.$store.commit("toggleSnackbar", {
            text: "Λανθασμένη Υποβολή",
          });
          return;
        }

        await this.uploadAttachments();

        await axios({
          method: "post",
          url: "/api/pde/job/add",
          data: this.form,
        });

        // show success message
        this.$store.commit("toggleSnackbar", {
          text: "Επιτυχής Υποβολή",
        });

        this.$router.go(-1);
      } catch (err) {
        console.log(err);
      } finally {
        this.submitLoader = false;
      }
    },
    // TODO: make it an action
    async uploadAttachments() {
      this.uploadLoader = true;
      try {
        let res, formData;

        // upload adamConfirmedRequest files
        if (this.selectedFiles.adamConfirmedRequestFiles.length > 0) {
          formData = new FormData();
          this.selectedFiles.adamConfirmedRequestFiles.forEach((file) => {
            formData.append("files", file, file.name);
          });
          res = await axios.post("/api/repository/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < res.data.data.length; i++) {
            this.form.pdeJobAdamConfirmedRequestAttachmentsIds.push(
              res.data.data[i].id
            );
          }
        }

        // upload adamPrimaryRequest files
        if (this.selectedFiles.adamPrimaryRequestFiles.length > 0) {
          formData = new FormData();
          this.selectedFiles.adamPrimaryRequestFiles.forEach((file) => {
            formData.append("files", file, file.name);
          });
          res = await axios.post("/api/repository/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < res.data.data.length; i++) {
            this.form.pdeJobAdamPrimaryRequestAttachmentsIds.push(
              res.data.data[i].id
            );
          }
        }

        // upload decisionProtocol files
        if (this.selectedFiles.decisionProtocolFiles.length > 0) {
          formData = new FormData();
          this.selectedFiles.decisionProtocolFiles.forEach((file) => {
            formData.append("files", file, file.name);
          });
          res = await axios.post("/api/repository/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < res.data.data.length; i++) {
            this.form.pdeJobDecisionProtocolAttachmentsIds.push(
              res.data.data[i].id
            );
          }
        }

        // upload jobAttachments files
        if (this.selectedFiles.jobAttachmentFiles.length > 0) {
          formData = new FormData();
          this.selectedFiles.jobAttachmentFiles.forEach((file) => {
            formData.append("files", file, file.name);
          });
          res = await axios.post("/api/repository/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < res.data.data.length; i++) {
            this.form.pdeJobAttachmentsIds.push(res.data.data[i].id);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.uploadLoader = false;
      }
    },
  },
  async created() {
    try {
      let endpoints = [
        "/api/pde/decision/list",
        "/api/pde/subsector/list",
        "/api/unit/list",
        "/api/municipality/list",
        "/api/job/type/list",
        "/api/job/phase/list",
      ];
      let [
        { data: decisionRes },
        { data: subsectorRes },
        { data: unitRes },
        { data: municipalityRes },
        { data: jobTypeRes },
        { data: jobPhaseRes },
      ] = await Promise.all(endpoints.map((endpoint) => axios(endpoint)));

      this.lists.decisions = decisionRes.data;
      this.lists.subsectors = subsectorRes.data;
      this.lists.units = unitRes.data;
      this.lists.municipalities = municipalityRes.data;
      this.lists.jobTypes = jobTypeRes.data;
      this.lists.jobPhases = jobPhaseRes.data;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss" scoped></style>
